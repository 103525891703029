// SUBMIT on .new_entry_sequence form input#sequence_submit
$(document).ready( function() {
  $('#new_box_sequence form').bind(
    'submit',function(event) {
      var $submit = document.getElementById('sequencetext');
      // the below method stops the default action of an element from happening
      // event.preventDefault();
      $submit.form.submit = function() { return false; };
      $('#sequence_new form input#sequence_sequence_text').focus();
  })
  });