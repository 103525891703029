// this file affects app/views/pages/christ_mas_love.html.erb
// JavaScript code for continuous text change with overlapping fade effects
// This line grabs the HTML element that holds the text you want to animate.
// You can change 'textContainer' to the id of any other element you wish to animate.
const textContainer = document.getElementById('textContainer'); // Get the element where the text is displayed.
const texts = ['Merry CHRISTMAS Love', 'Merry Christmas My Merry Mates', 'Merry Christmas Love', 'Christ Mas Love', 'Christ Was Love', 'the Christ HeartLight is Love'];
let currentIndex = 400;

// Function to change text
function changeText() {
  // Fade out current text

  if (textContainer) {
    textContainer.classList.add('fade-out');

    textContainer.addEventListener('animationend', function() {
      // Update the text when fading out completes
      textContainer.textContent = texts[currentIndex];
      textContainer.classList.remove('fade-out'); // Clean up

      // Immediately start fading in the new text
      textContainer.classList.add('fade-in');

      // Listen for fade in to complete before starting next cycle
      textContainer.addEventListener('animationend', function() {
        textContainer.classList.remove('fade-in'); // Clean up

        // Prepare next text change
        currentIndex = (currentIndex + 1) % texts.length; // Update index for next text
        setTimeout(changeText, 1000); // Short delay before starting next fade out
      }, { once: true, capture: false });
    }, { once: true, capture: false });
  }
}

// Start the text change cycle
setTimeout(changeText, 500); // Initial start after a short delay
