// FOCUSIN FOCUSOUT on .new_entry_sequence form input#sequencetext
// Query(function ($) { $(document).ready(  function() {
  $(document).ready(function() {
    $("#new_box_sequence .new_entry_sequence form input#sequencetext").focus();
    $("#twotab").hide();
    $(".sequence_new form .form-group label.hiding").stop(true, true).fadeTo(100, 0.1);
    $(".sequence_new form .form-group label.showing").stop(true, true).fadeTo(100, 0.1);
    $(".sequence_new form .form-group input#sequence_sequence_text").stop(true, true).fadeTo(100, 0.1);
    $(".sequence_new form .form-group input#sequence_sequence_creation_id").hide();
    $(".sequence_new form .form-group input#sequence_sequence_complete_id").hide();
    $(".sequence_new form .form-group input#sequence_sequence_lexigram_id").hide();
    $(".sequence_new form .form-group input#sequence_sequence_singular_id").hide();
    $(".sequence_new form .form-group label#sequencelense").hide();
    $(".sequence_new form .form-group label#sequencedescription").hide();
    $(".sequence_new form .form-group input#sequence_description").hide();
    $(".sequence_new form .form-group label#sequencereference").hide();
    $(".sequence_new form .form-group input#sequence_reference").hide();
    $(".sequence_new form .form-group label#sequenceurl").hide();
    $(".sequence_new form .form-group input#sequence_url").hide();
    $("span.creationcounts").hide();
    $("span.completecounts").hide();
    $("span.lexigramcounts").hide();
    $("span.singularcounts").hide();
    
    $(".new_entry_sequence form input#sequencetext").bind("focusin", function(event) {
      if ($("input#sequencetext").val() == "Please Enter Your Data, then tab out") {
        $("#twotab").stop(true, true).fadeTo(100, 0.1);
        $("input#sequencetext").stop(true, true).fadeTo(300, 0.7).delay(100).val("");
        $("input#sequencetext").stop(true, true).fadeTo(300, 1.0);
        $(".sequence_new form .form-group input#sequence_sequence_lense").hide();
      } else {
        $("#twotab").stop(true, true).fadeTo(200, 0.9);
        $(".sequence_new form .form-group label#sequencelense").stop(true, true).fadeTo(100, 0.6);
        $(".sequence_new form .form-group input#sequence_sequence_lense").stop(true, true).fadeTo(300, 0.4);
      }
    }).bind("focusout", function(event) {
      $("#twotab").stop(true, true).fadeTo(200, 0.1);
      $(".form-group").stop(true, true).fadeTo(100, 0.9);
      $(".sequence_new form .form-group label.hiding").stop(true, true).fadeTo(100, 1.0);
      $(".sequence_new form .form-group label.showing").stop(true, true).fadeTo(200, 1.0);
      $(".sequence_new form .form-group input#sequence_sequence_text").stop(true, true).fadeTo(300, 0.9);
      $(".sequence_new form .form-group input#sequence_sequence_creation_id").stop(true, true).fadeTo(300, 0.9);
      $(".sequence_new form .form-group input#sequence_sequence_complete_id").stop(true, true).fadeTo(300, 0.9);
      $(".sequence_new form .form-group input#sequence_sequence_lexigram_id").stop(true, true).fadeTo(300, 0.9);
      $(".sequence_new form .form-group input#sequence_sequence_singular_id").stop(true, true).fadeTo(300, 0.9);
      $(".sequence_new form .form-group input#sequence_sequence_lense").stop(true, true).fadeTo(100, 0.9);
      $(".sequence_new form .form-group label#sequencelense").stop(true, true).fadeTo(100, 1.0);
      $(".sequence_new form .form-group input#sequence_sequence_lense").stop(true, true).fadeTo(100, 1.0);
      // Spinner activity and further logic follows
      // ... (include the rest of your logic here, following the same pattern for stopping animations)
      // my request was again not followed.
      // if you would give me the next line, when you do this .. // Spinner activity and further logic follows
      // that would really help. This instruction, following the same pattern for stopping animations, is not helpful.
      // this is helpful : "follow the same pattern for stopping animations prior to every .fadeTo() call" that would have said it all.
      // out of alignment, will fix later, after we get the code working.
          var $submit = document.getElementById("sequence_sequence_text");
          $("#spinner").activity({outside: true, align: "left", segments: 1});
          $(".lensenotice").remove(); // see new_sequence_lense_verification.js
          $(".sequence_new form input#docNewCancelButton").stop(true, true).fadeTo(100, 1.0);
          $(".sequence_new form .form-group label#sequencedescription").stop(true, true).fadeTo(100, 1.0);
          $(".sequence_new form .form-group input#sequence_description").stop(true, true).fadeTo(300, 1.0);
          $(".sequence_new form .form-group label#sequencereference").stop(true, true).fadeTo(100, 1.0);
          $(".sequence_new form .form-group input#sequence_reference").stop(true, true).fadeTo(300, 1.0);
          $(".sequence_new form .form-group label#sequenceurl").stop(true, true).fadeTo(100, 1.0);
          $(".sequence_new form .form-group input#sequence_url").stop(true, true).fadeTo(300, 1.0);
          var $entry = $("input#sequencetext").val();
          var $sequencetext = $("input#sequencetext").serialize();
          console.log("The values of the entry and sequencetext variables are: " + $entry + ", " + $sequencetext);
          var $text_return_value = "";
          var $creation_return_value = "";
          var $complete_return_value = "";
          var $lexigram_return_value = "";
          var $singular_return_value = "";
          var $singularscount = 0;
          var $singularcounts = "";
          var $lexigramscount = 0;
          var $lexigramcounts = "";
          var $completescount = 0;
          var $completescounts = "";
          var $creationscount = 0;
          var $creationscounts = "";
          var $textscount = 0;
          var $textscounts = "";
  
          if ($entry == "") {
            $("input#sequencetext").stop(true, true).fadeTo(300, 0.3).end();
            $("input#sequencetext").val("Please Enter Your Data, then tab out").delay(100).end();
            $(".sequence_new form label .showing").stop(true, true).fadeTo(300, 0.7);  // affects only sequence_text
            $("input#sequencetext").stop(true, true).fadeTo(300, 1.0);
            $("input#sequence_sequence_text").val("");
            $("input#sequence_sequence_creation_id").val("");
            $("input#sequence_sequence_complete_id").val("");
            $("input#sequence_sequence_lexigram_id").val("");
            $("input#sequence_sequence_singular_id").val("");
            $("input#sequence_sequence_lense").val("");
            $("#spinner").activity(false);
            }
          else {
            var $entry_creation_text = $("input#sequence_sequence_text").val();
            if ($entry_creation_text == "no letters remain after processing") {
              }
          if ($entry != "") {
            if ($entry != "Enter data, then tab out") {
              if ($entry != "Enter Your Data, then tab out") {
                if ($entry != "Please Enter Your Data, then tab out") {
                  if ($entry_creation_text != "no letters remain after processing") {
                    if ($entry_creation_text != "enter a new entry") {
  
              $("#spinner").activity({outside: true, align: "center", segments: 1});
              $("label.showing").stop(true, true).fadeTo(100, 0.9);
              $("span.textcounts").stop(true, true).fadeTo(100, 0.9);
              console.log("The values of the entry and sequencetext variables are: " + $entry_creation_text + ", " + $entry_creation_text);
  
            $(".sequence_new form .form-group input#sequence_sequence_text").stop(true, true).fadeTo(100, 0.9);
            $text_return_value = $.ajax({	type: "GET", url: "/sequences/text_sequenced", data: $sequencetext, async: false, dataType: "script", success: function(data) { }  }).responseText;
            $text_return_value = $.trim($text_return_value);
            console.log("The values of the $text_return_value is: " + $text_return_value);
  
     $("#textings").append(" = " + $text_return_value).delay(100).end();
     $(".sequence_new form .form-group input#sequence_sequence_text").val($text_return_value);
            $("#spinner").activity(false);
            var $letters_text_count = $text_return_value.length;
            console.log("The values of the $letters_text_count is: " + $letters_text_count);
  
            $("#spinner").activity({outside: true, align: "left", segments: 6});
            $textscount = $.ajax({ type: "GET", url: "/sequences/texts_count", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $textcounts = (" " + $textscount);
            console.log("The values of the $textscount is: " + $textscount);
  
            $("#counttexts").text($textcounts);
            $("#spinner").activity(false);
  
            $("span.creationcounts").stop(true, true).fadeTo(100, 0.9);
  
            $("#spinner").activity({outside: true, align: "left", segments: 2});
            $(".sequence_new form .form-group input#sequence_sequence_creation_id").stop(true, true).fadeTo(100, 0.9);
            $creation_return_value = $.ajax({	type: "GET", url: "/creations/creation_sequenced", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $creation_return_value = $.trim($creation_return_value);
            console.log("The value of the $creation_return_value is: " + $creation_return_value);
            $("#spinner").activity(false);
  
     $("#creatings").append(" = " + $creation_return_value).delay(100);
  // $(".sequence_new form .form-group input#sequence_sequence_creation_id").val($creation_return_value);
  
            var $letters_creation_count = $creation_return_value.length;
            $("#spinner").activity({outside: true, align: "left", segments: 5});
            $creationscount = $.ajax({ type: "GET", url: "/creations/creations_count", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $creationscount = $.trim($creationscount);
            $creationcounts = (" " + $creationscount);
            console.log("The value of the $creationscount is: " + $creationscount);
            console.log("The value of the $creationcounts is: " + $creationcounts);
  
            $("#countcreations").text($creationcounts);
            $("#spinner").activity(false);
  
            $("span.completecounts").stop(true, true).fadeTo(100, 0.9);
  
            $("#spinner").activity({outside: true, align: "left", segments: 3});
            $(".sequence_new form .form-group input#sequence_sequence_complete_id").stop(true, true).fadeTo(100, 0.9);
            $complete_return_value = $.ajax({	type: "GET", url: "/completes/complete_sequenced", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $complete_return_value = $.trim($complete_return_value);
            console.log("The value of the $complete_return_value is: " + $complete_return_value);
  
            $("#spinner").activity(false);
  
     $("#completings").append(" = " + $complete_return_value).delay(100).end();
  // $(".sequence_new form .form-group input#sequence_sequence_complete_id").val($complete_return_value);
  
            var $letters_complete_count = $complete_return_value.length;
            $("#spinner").activity({outside: true, align: "left", segments: 4});
            $completescount = $.ajax({ type: "GET", url: "/completes/completes_count", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $completescount = $.trim($completescount);
            $completecounts = (" " + $completescount);
            console.log("The value of the $completescount is: " + $completescount);
            console.log("The value of the $completecounts is: " + $completecounts);
  
            $("#countcompletes").text($completecounts);
            $("#spinner").activity(false);
  
            $("span.lexigramcounts").stop(true, true).fadeTo(100, 0.9);
  
            $("#spinner").activity({outside: true, align: "left", segments: 4});
            $(".sequence_new form .form-group input#sequence_sequence_lexigram_id").stop(true, true).fadeTo(100, 0.9);
            $lexigram_return_value = $.ajax({	type: "GET", url: "/lexigrams/lexigram_sequenced", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $lexigram_return_value = $.trim($lexigram_return_value);
            console.log("The value of the $lexigram_return_value is: " + $lexigram_return_value);
  
            $("#spinner").activity(false);
  
     $("#lexigramings").append(" = " + $lexigram_return_value).delay(100).end();
  // $(".sequence_new form .form-group input#sequence_sequence_lexigram_id").val($lexigram_return_value);
            var $letters_lexigram_count = $lexigram_return_value.length;
            $("#spinner").activity({outside: true, align: "left", segments: 3});
            $lexigramscount = $.ajax({ type: "GET", url: "/lexigrams/lexigrams_count", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $lexigramscount = $.trim($lexigramscount);
            $lexigramcounts = (" " + $lexigramscount);
            console.log("The value of the $lexigramscount is: " + $lexigramscount);
            console.log("The value of the $lexigramcounts is: " + $lexigramcounts);
  
            $("#countlexigrams").text($lexigramcounts);
            $("#spinner").activity(false);
  
              $("span.singularcounts").stop(true, true).fadeTo(100, 0.9);
  
            $("#spinner").activity({outside: true, align: "left", segments: 5});
            $(".sequence_new form .form-group input#sequence_sequence_singular_id").stop(true, true).fadeTo(100, 0.9);
            $singular_return_value = $.ajax({	type: "GET", url: "/singulars/singular_sequenced", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $singular_return_value = $.trim($singular_return_value);
            console.log("The value of the $singular_return_value is: " + $singular_return_value);
  
            $("#spinner").activity(false);
  
     $("#singularings").append(" = " + $singular_return_value).delay(100).end();
  // $(".sequence_new form .form-group input#sequence_sequence_singular_id").val($singular_return_value);
            var $letters_singular_count = $singular_return_value.length;
            console.log("The value of the $letters_singular_count is: " + $letters_singular_count);
  
            $("#spinner").activity({outside: true, align: "left", segments: 4});
            $singularscount = $.ajax({ type: "GET", url: "/singulars/singulars_count", data: $sequencetext, async: false, dataType: "script", success: function(data) { } }).responseText;
            $singularscount = $.trim($singularscount);
            $singularcounts = (" " + $singularscount);
            console.log("The value of the $singularscount is: " + $singularscount);
            console.log("The value of the $singularcounts is: " + $singularcounts);
  
            $("#countsingulars").text($singularcounts);
            $("span.singularcounts").stop(true, true).fadeTo(100, 0.9);
            $("#spinner").activity(false);
            $("#spinner").activity({outside: true, align: "left", segments: 4});
            $("#spinner").activity(false);
  
                    // fifth updated 202404061859
                    if ($singularscount == "0") {
                      $("#spinner").activity({outside: true, align: "center", segments: 7});
                      $(`<div class='singularcounts col-sm-6'>${$letters_singular_count} letters : ${$singularscount} matches </div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("input#sequence_sequence_singular_id");
                      $("#spinner").activity(false);
                    }
                    else if ($singularscount == "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $(`<div class='singularcounts col-sm-6'>${$letters_singular_count} letters : ${$singularscount} match : please see <a href="/singulars/list?sequencetext=${$text_return_value}" target="_blank" rel="noopener">Singulars Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("input#sequence_sequence_singular_id");
                      $("#spinner").activity(false);
                      }
                    else if ($singularscount > "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $(`<div class="singularcounts col-sm-6">${$letters_singular_count} letters : ${$singularscount} matches : please see <a href="/singulars/list?sequencetext=${$text_return_value}" target="_blank" rel="noopener">Singulars Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("input#sequence_sequence_singular_id");
                      $("#spinner").activity(false);
                    }
  
                    // template for matches grammars
                    // fourth updated 202404061858
                    if ($lexigramscount == "0") {
                      $("#spinner").activity({outside: true, align: "center", segments: 7});
                      $(`<div class="lexigramcounts col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;${$letters_lexigram_count} letters : ${$lexigramscount} matches</div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_lexigram_id");
                      $("#spinner").activity(false);
                    }
                    else if ($lexigramscount == "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $(`<div class="lexigramcounts col-sm-6">${$letters_lexigram_count} letters : ${$lexigramscount} match : please see <a href="/lexigrams/list?sequencetext=${$lexigram_return_value}" target="_blank" rel="noopener">Lexigrams Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_lexigram_id");
                      $("#spinner").activity(false);
                    }
                    else if ($lexigramscount > "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $(`<div class="lexigramcounts col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;${$letters_lexigram_count} letters : ${$lexigramscount} matches : please see <a href="/lexigrams/list?sequencetext=${$lexigram_return_value}" target="_blank" rel="noopener">Lexigrams Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_lexigram_id");
                      $("#spinner").activity(false);
                    }
                  
  
                      /* third updated 202404061854 */
                      if ($completescount == "0") {
                        $("#spinner").activity({outside: true, align: "center", segments: 7});
                        $(`<div class="completecounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_complete_count} letters : ${$completescount} matches</div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_complete_id");
                        $("#spinner").activity(false);
                      }
                      else if ($completescount == "1") {
                        $("#spinner").activity({outside: true, align: "center", segments: 8});
                        $(`<div class="completecounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_complete_count} letters : ${$completescount} match : please see <a href="/completes/list?sequencetext=${$complete_return_value}" target="_blank" rel="noopener">Completes Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_complete_id");
                        $("#spinner").activity(false);
                      }
                      else if ($completescount > "1") {
                        $("#spinner").activity({outside: true, align: "center", segments: 8});
                        $(`<div class="completecounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_complete_count} letters : ${$completescount} matches : please see <a href="/completes/list?sequencetext=${$complete_return_value}" target="_blank" rel="noopener">Completes Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_complete_id");
                        $("#spinner").activity(false);
                      }
                    /* first one done 202404061843 */
                    if ($creationscount == "0") {
                      $("#spinner").activity({outside: true, align: "center", segments: 7});
                      $(`<div class="creationcounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_creation_count} letters : ${$creationscount} matches</div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_creation_id");
                      $("#spinner").activity(false);
                    }
                    else if ($creationscount == "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $("label#sequence_sequence_creation_id").hide();
                      var $creationings = $(`<div class="creationcounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_creation_count} letters : ${$creationscount} match : please see <a href="/creations/list?sequencetext=${$creation_return_value}" target="_blank" rel="noopener">Creations Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_creation_id");
                      $("#spinner").activity(false);
                    }
                    else if ($creationscount > "1") {
                      $("#spinner").activity({outside: true, align: "center", segments: 8});
                      $(`<div class="creationcounts col-sm-6">&nbsp;&nbsp;&nbsp;${$letters_creation_count} letters : ${$creationscount} matches : please see <a href="/creations/list?sequencetext=${$creation_return_value}" target="_blank" rel="noopener">Creations Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_creation_id");
                      $("#spinner").activity(false);
                    }
                    if ($creationscount > $textscount) {
                      $("#spinner").activity({outside: true, align: "center", segments: 9});
                      $(".creationcounts").append(" : This is a new match on this Creation sequence");
                      $("#spinner").activity(false);
                    }
  
                    /* 202404061853 second one updated  */
                    if ($textscount == "0") {
                        $("#spinner").activity({outside: true, align: "center", segments: 2});
                        $(`<div class="textcounts col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${$letters_text_count} letters : ${$textscount} matches</div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_text");
                        $("#spinner").activity(false);
                    }
                      else if ($textscount == "1") {
                        $("#spinner").activity({outside: true, align: "center", segments: 2});
                        $(`<div class="textcounts col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${$letters_text_count} letters : ${$textscount} match : please see <a href="/sequences/text_list?sequencetext=${$text_return_value}" target="_blank" rel="noopener">Texts Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_text");
                        $("#spinner").activity(false);
                      }
                      else if ($textscount > "1") {
                        $("#spinner").activity({outside: true, align: "center", segments: 2});
                        $(`<div class="textcounts col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${$letters_text_count} letters : ${$textscount} matches : please see <a href="/sequences/text_list?sequencetext=${$text_return_value}" target="_blank" rel="noopener">Texts Search</a></div>`).stop(true, true).fadeTo(100, 0.9).insertBefore("#sequence_sequence_text");
                        $("#counttexts").append(" You have one or more matches, please see Texts Search").show();
                        $("#spinner").activity(false);
                      }
                    
                      $(".sequence_new form label#sequencelense").stop(true, true).fadeTo(500, 1.0);
                      $(".sequence_new form label#sequencelense").focus();
              }
              }
              }
              }
              }
              }
      }
    });
  });
  