$(document).ready(function() {
  var $textInputForm = $('.good_works_search_form form')
  var $textInput = $textInputForm.find('input#sequencetext');
  
  // Set focus on the text input initially
  $textInput.focus();

  // Handle form submission
  $textInputForm.on('submit', function(event) {
      var searchInput = $textInput.val().trim();
      // Check if the input is empty
      if (!searchInput) {
          console.log("Preventing form submission due to empty input.");
          event.preventDefault(); // Prevent form submission
          alert('Please enter a search term.');
          return false;
      }
  });
});
