import { createGrid } from 'ag-grid-community';
import 'ag-grid-enterprise';

;(function () {

  "use strict";
  var SequenceIndex = function () {
    var $toggleButton = $('#toggle-filter');
    var $filterForm = $('#filter-form');
    var $myGrid = $('#myGrid');
    
    var initializeTable = function() {
    const sequencesDataElement = document.getElementById('sequences-data');
     const rowData = JSON.parse(sequencesDataElement.dataset.json);

      const columnDefs = [
        {
          headerName: 'Actions',
          field: 'id',
          width: '100',
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences/${params.value}"  class='ag-link'>View</a>`;
            const editLink = `<a href="/v2/sequences/${params.value}/edit">Edit</a>`;
            const deleteLink = `<a href="/v2/sequences/${params.value}" data-method="delete" data-confirm="Are you sure?">Delete</a>`;
            return `${viewLink}`;
          },
          resizable: true
        },
        { headerName: 'Texts', field: 'sequence_text', resizable: true, enableRangeSelection: true, 
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_text", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
        },
        { headerName: 'Creations', field: 'sequence_creation', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_creation", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
        },
        { headerName: 'Completes', field: 'sequence_complete', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_complete", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Lexigrams', field: 'sequence_lexigram', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_lexigram", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Singulars', field: 'sequence_singular', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_singular", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Lenses', field: 'sequence_lense', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a class='ag-link content' href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_lense", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Description', field: 'description', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<span class='ag-link content'>${params.value}</span>`;
            return `${viewLink}`;
          }
        },
        { headerName: 'Url', field: 'url', resizable: true,
          cellRenderer: function(params) {
            const viewLink = `<span class='ag-link content'>${params.value}</span>`;
            return `${viewLink}`;
          }
        },
        { headerName: 'Reference', field: 'reference', resizable: true,
          cellRenderer: function(params) {
            const viewLink = `<span class='ag-link content'>${params.value}</span>`;
            return `${viewLink}`;
          }
         }
      ]

      const gridOptions = {
        columnDefs: columnDefs,
        rowData: rowData,
            // Define the context menu with custom options
        getContextMenuItems: function (params) {
          let defaultItems = params.defaultItems;  // Include default items

          // Filter out specific items you want to remove
          defaultItems = defaultItems.filter(item => {
            return item == 'copy';
          });
          // Define custom context menu items
          const customItems = [
            'separator',  // Add a separator
            {
              name: 'Custom Action',  // Custom menu item label
              action: function () {
                alert('Custom Action: ' + JSON.stringify(params.node.data));  // Custom action
              },
             icon: '<i class="fas fa-edit"></i>'  // Optionally, add an icon (uses FontAwesome in this case)
            },
            'separator'  // Another separator if needed
          ];

          // Return both default and custom menu items
          return defaultItems.concat(customItems);
        },
      };
  
      const eGridDiv = document.querySelector('#myGrid');
      const gridApi =  createGrid(eGridDiv, gridOptions);
    };

    var initializeFilterToggle = function() {
      $toggleButton.on('click', function() {
        $filterForm.slideToggle();
      });
    };

    this.init = function() {
      initializeFilterToggle();
      initializeTable();
    }
  };

  $(document).ready(function () {
  if ($('body').data('controller') === 'sequences' && ($('body').data('action') === 'advanced_search') || ($('body').data('action') === 'index')) {
      var sequenceIndex = new SequenceIndex();
      sequenceIndex.init();
    }
  });
})();