import { Grid } from 'ag-grid-community';

$(document).ready(function() {
  if ($('body').data('controller') === 'sequences' && $('body').data('action') === 'limited') {
    const sequencesDataElement = document.getElementById('sequences-data');
    const rowData = JSON.parse(sequencesDataElement.dataset.json);

    const columnDefs = [
      { headerName: 'Texts', field: 'sequence_text', resizable: true, enableRangeSelection: true, },
      { headerName: 'Creations', field: 'sequence_creation', resizable: true, enableRangeSelection: true,},
      { headerName: 'Completes', field: 'sequence_complete', resizable: true, enableRangeSelection: true,},
      { headerName: 'Lexigrams', field: 'sequence_lexigram', resizable: true, enableRangeSelection: true,},
      { headerName: 'Singulars', field: 'sequence_singular', resizable: true, enableRangeSelection: true,},
      { headerName: 'Lenses', field: 'sequence_lense', resizable: true, enableRangeSelection: true,},
      { headerName: 'Description', field: 'description', resizable: true, enableRangeSelection: true},
      { headerName: 'Url', field: 'url', resizable: true },
      { headerName: 'Reference', field: 'reference', resizable: true },
    ]

    const gridOptions = {
      columnDefs: columnDefs,
      rowData: rowData,
      
      gridOptions: {
        enableRangeSelection: true,
        clipboard: {
          suppressLastEmptyLine: true
        }
      }
    };

    const eGridDiv = document.querySelector('#myGridLimited');
    new Grid(eGridDiv, gridOptions);

    const styleElements = document.querySelectorAll('style');
    styleElements.forEach((style) => {
      style.setAttribute('nonce', nonce);
    });
  }
});