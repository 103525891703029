// SUBMIT on .new_entry_sequence form input#sequence_submit
$(document).ready( function() {
  $('.sequence_edits form').bind(
    'submit',function(event) {
      var $sequence_sequence_text = $('.sequence_edits form input#sequence_sequence_text').val();
      var $sequencetext = $('.sequence_edit form input#sequencetext').val();
      var $submit = document.getElementById('sequence_sequence_text');
      $submit.form.submit = function() { return false; };
      if ($sequencetext === "") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 0.9);
        $('.sequence_edits form input#sequence_sequence_text').val("Enter Your Data, then tab out");
        event.preventDefault();
        $submit.form.submit = function() { return false; };
        $('.sequence_edit form input#sequencetext').focus();
        }
      else if ($sequencetext === "Enter Data, then tab out") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 0.9);
        $('.sequence_edits form input#sequence_sequence_text').val("Please enter data above");
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext === "Enter Your Data, then tab out") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 1.0);
        $('.sequence_edits form input#sequence_sequence_text').val("Please enter data above");
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext === "Please Enter Your Data, then tab out") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 1.0);
        $('.sequence_edits form input#sequence_sequence_text').val("Enter Data, then tab out");
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequence_sequence_text === "no letters remain after processing") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 1.0);
        $('.sequence_edits form input#sequence_sequence_text').val("enter a new entry");
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequence_sequence_text === "enter a new entry") {
        $('.sequence_edit form input#sequencetext').fadeTo(700, 0.2);
        $('.sequence_edit form input#sequencetext').fadeTo(400, 1.0);
        $('.sequence_edits form input#sequence_sequence_text').val("no letters remain after processing");
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else {
        if ($('.sequence_edits form input#sequence_sequence_text').val() !== "no letters remain after processing") {
          if ($('.sequence_edits form input#sequence_sequence_text').val() !== "enter a new entry") {
            if ($('.sequence_edits form input#sequence_sequence_text').val() !== "") {
              if ($('.sequence_edits form input#sequence_sequence_text').val() !== "Enter Your Data, then tab out") {
                $submit.form.submit("");
              }
            }
          }
        }
      }
    })
  });
