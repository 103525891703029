
// FOCUSIN on form input#sequencetext
$(document).ready(  function() {
  var $toEdit = $('.sequence_edits form input#sequence_sequence_text').val();
  var $sequencetext = $toEdit;
  $('.sequence_edit form input#sequencetext').val($toEdit).fadeTo(400, 0.4);
  $('.sequence_edit form input#sequencetext').fadeTo(400, 1.0);
  $('.sequence_edit form input#sequencetext').bind('focusin',function(event) {
    $('span.textcounts').empty();
    $('span.creationcounts').empty();
    $('span.completecounts').empty();
    $('span.lexigramcounts').empty();
    $('span.singularcounts').empty();
    }).bind('focusout',function(event) {
      $('#spinner').activity({outside: true, align: 'left', segments: 8});
      var $newsequence = document.getElementById('sequencetext');
      var $sequenceSubmit = document.getElementById('sequence_sequence_text');
      $newsequence.form.onsubmit = function() { return false; };
      $sequenceSubmit.form.submit = function() { return false; };
      var $text_return_value = "";
      var $creation_return_value = "";
      var $complete_return_value = "";
      var $lexigram_return_value = "";
      var $singular_return_value = "";
      var $singularscount = 0;
      //  var $singularcounts = "";
      var $lexigramscount = 0;
      //  var $lexigramcounts = "";
      var $completescount = 0;
      //  var $completescounts = "";
      var $creationscount = 0;
      //  var $creationscounts = "";
      var $textscount = 0;
      //  var $textscounts = "";

      $('#spinner').activity(false);
      $('#spinner').activity({outside: true, align: 'left', segments: 1});
      var $toEnter = $('.sequence_edit form input#sequencetext').val();
      if ($toEnter == "") {
        $('#spinner').activity(false);
        $('input#sequencetext').val("Please Enter Your Data, then tab out");
        }
      else if ($toEnter != "") {
        if ($toEnter != "Enter data, then tab out") {
          if ($toEnter != "Enter Your Data, then tab out") {
            if ($toEnter != "Please Enter Your Data") {
              if ($toEnter != "Please Enter Your Data, then tab out") {
                if ($toEnter != "no letters remain after processing") {
                  var $newsequence = document.getElementById('sequencetext_submit');
                  var $sequenceSubmit = document.getElementById('sequence_sequence_text');
                  $newsequence.form.onsubmit = function() { return false; };
                  $sequenceSubmit.form.submit = function() { return false; };
                  $('#spinner').activity(false);

                  var $sequencetext = $('.sequence_edit form input#sequencetext').serialize();
                  $('#spinner').activity({outside: true, align: 'center', segments: 3});
                  $('.sequence_edits form .form-group input#sequence_sequence_text, label#textings').fadeTo(200, 0.9);
                  $text_return_value = $.ajax({	type: "GET",    url: "/sequences/text_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) { }  }).responseText;
                  $('.sequence_edits form .form-group input#sequence_sequence_text').val($text_return_value);
                  $('#spinner').activity(false);

                  $('#spinner').activity({outside: true, align: 'center', segments: 2});
                  $('.sequence_edits form .form-group input#sequence_sequence_creation_id, label#creatings').fadeTo(300, 0.9);
                  $creation_return_value = $.ajax({	type: "GET",    url: "/creations/creation_id_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $creation_return_sequence = $.ajax({	type: "GET",    url: "/creations/creation_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $('#spinner').activity(false);
                  $('.sequence_edits form .form-group input#sequence_sequence_creation_id').val($creation_return_value);
                  // $('.sequence_edits form .form-group input#sequence_sequence_creation_id').insertAfter.val($creation_return_sequence);

                  $('#spinner').activity({outside: true, align: 'center', segments: 3});
                  $('.sequence_edits form .form-group input#sequence_sequence_text, label#textings');
                  $('.sequence_edits form .form-group input#sequence_sequence_complete_id, label#completings').fadeTo(300, 0.9);
                  $complete_return_value = $.ajax({	type: "GET", url: "/completes/complete_id_sequenced", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $complete_return_sequence = $.ajax({	type: "GET", url: "/completes/complete_sequenced", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $('#spinner').activity(false);
                  $('.sequence_edits form .form-group input#sequence_sequence_complete_id').val($complete_return_value);
                  // $('.sequence_edits form .form-group input#sequence_sequence_complete_id').val($complete_return_sequence);
                  // $($complete_return_sequence).prependTo('.sequence_edits form .form-group input#sequence_sequence_complete_id');

                  $('#spinner').activity({outside: true, align: 'center', segments: 4});
                  $('.sequence_edits form .form-group input#sequence_sequence_lexigram_id, label#lexigramings').fadeTo(300, 0.9);
                  $lexigram_return_value = $.ajax({	type: "GET",    url: "/lexigrams/lexigram_id_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $lexigram_return_sequence = $.ajax({	type: "GET",    url: "/lexigrams/lexigram_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $('#spinner').activity(false);
                  $('.sequence_edits form .form-group input#sequence_sequence_lexigram_id').val($lexigram_return_value);
                  // $('.sequence_edits form .form-group input#sequence_sequence_lexigram_id').val($lexigram_return_sequence);

                  $('#spinner').activity({outside: true, align: 'center', segments: 5});
                  $('.sequence_edits form .form-group input#sequence_sequence_singular_id, label#singularings').fadeTo(300, 0.9);
                  $singular_return_value = $.ajax({	type: "GET",    url: "/singulars/singular_id_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $singular_return_sequence = $.ajax({	type: "GET",    url: "/singulars/singular_sequenced",    data: $sequencetext,    async: true,    dataType: 'script',    success: function(data) {  } }).responseText;
                  $('#spinner').activity(false);
                  $('.sequence_edits form .form-group input#sequence_sequence_singular_id').val($singular_return_value);
                  // $('.sequence_edits form .form-group input#sequence_sequence_singular_id').val($singular_return_sequence);

                  $('#spinner').activity({outside: true, align: 'center', segments: 6});
                  var $letters_text_count = $creation_return_sequence.length;
                  var $letters_creation_count = $creation_return_sequence.length;
                  var $letters_complete_count = $complete_return_sequence.length;
                  var $letters_lexigram_count = $lexigram_return_sequence.length;
                  var $letters_singular_count = $singular_return_sequence.length;
                  $('#spinner').activity(false);
                  $('#spinner').activity({outside: true, align: 'center', segments: 5});
                  $singularscount = $.ajax({ type: "GET", url: "/singulars/singulars_count", data: $sequencetext, async: true, dataType: 'script', success: function(data) {  }  }).responseText;
                  $('#spinner').activity(false);

                  if ($singularscount == '0') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#singularings').append(" = " + $singular_return_sequence).fadeTo(100, 1.0);
                    $("<span class='singularcounts'> &nbsp; ~ " + $letters_singular_count + " letters : " + $singularscount + " matches </span>").fadeTo(1500, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_singular_id');
                    $('#spinner').activity(false);
                  }

                  if ($singularscount == '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#singularings').append(" = " + $singular_return_sequence).fadeTo(100, 1.0);
                    $("<span class='singularcounts'> &nbsp;&nbsp; ~ " + $letters_singular_count + " letters : " + $singularscount + " match\n <a href='/singulars/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Singulars Search</a></span>").fadeTo(1500, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_singular_id');
                    $('#spinner').activity(false);
                  }

                  if ($singularscount > '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#singularings').append(" = " + $singular_return_sequence).fadeTo(100, 1.0);
                    $("<span class='singularcounts'> &nbsp;&nbsp; ~ " + $letters_singular_count + " letters : " + $singularscount + " matches\n <a href='/singulars/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Singulars Search</a></span>").fadeTo(1500, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_singular_id');
                    $('#spinner').activity(false);
                  }

                  $('#spinner').activity({outside: true, align: 'center', segments: 3});
                  $lexigramscount = $.ajax({ type: "GET", url: "/lexigrams/lexigrams_count", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $('#spinner').activity(false);
                  $('#spinner').activity({outside: true, align: 'center', segments: 4});

                  if ($lexigramscount == '0') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#lexigramings').append(" = " + $lexigram_return_sequence).fadeTo(200, 1.0);
                    $("<span class='lexigramcounts'> &nbsp;&nbsp; ~ " + $letters_lexigram_count + " letters : " + $lexigramscount + " matches </span>").fadeTo(1300, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_lexigram_id');
                    $('#spinner').activity(false);
                  }

                  if ($lexigramscount == '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#lexigramings').append(" = " + $lexigram_return_sequence).fadeTo(300, 1.0);
                    $("<span class='lexigramcounts'> &nbsp;&nbsp; ~ " + $letters_lexigram_count + " letters : " + $lexigramscount + " match\n <a href='/lexigrams/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Lexigrams Search</a></span>").fadeTo(1300, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_lexigram_id');
                    $('#spinner').activity(false);
                  }

                  if ($lexigramscount > '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#lexigramings').append(" = " + $lexigram_return_sequence).fadeTo(300, 1.0);
                    $("<span class='lexigramcounts'> &nbsp; ~ " + $letters_lexigram_count + " letters : " + $lexigramscount + " matches\n <a href='/lexigrams/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Lexigrams Search</a></span>").fadeTo(1300, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_lexigram_id');
                    $('#spinner').activity(false);
                  }

                  $('#spinner').activity({outside: true, align: 'center', segments: 3});
                  $completescount = $.ajax({ type: "GET", url: "/completes/completes_count", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $('#spinner').activity(false);

                  if ($completescount == '0') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#completings').append(" = " + $complete_return_sequence).fadeTo(400, 1.0);
                    $("<span class='completecounts'> &nbsp; ~ " + $letters_complete_count + " letters : " + $completescount + " matches </span>").fadeTo(1100, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_complete_id');
                    $('#spinner').activity(false);
                  }

                  if ($completescount == '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#completings').append(" = " + $complete_return_sequence).fadeTo(400, 1.0);
                    $("<span class='completecounts'> &nbsp; ~ " + $letters_complete_count + " letters : " + $completescount + " match\n <a href='/completes/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Completes Search</a></span>").fadeTo(1100, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_complete_id');
                    $('#spinner').activity(false);
                  }

                  if ($completescount > '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#completings').append(" = " + $complete_return_sequence).fadeTo(400, 1.0);
                    $($complete_return_sequence + " ~ <span class='completecounts'> &nbsp; ~ "  + $letters_complete_count + " letters : " + $completescount + " matches\n <a href='/completes/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Completes Search</a></span>").fadeTo(1100, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_complete_id');
                    // original $($complete_return_sequence + "<span class='completecounts'> &nbsp; ~ "  + $letters_complete_count + " letters : " + $completescount + " matches : <a href='/completes/list?sequencetext=" + $complete_return_value + "' target='_blank' rel='noopener'>please see Completes Search</a></span>").fadeTo(4000, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_complete_id');
                    $('#spinner').activity(false);
                  }

                  $('#spinner').activity({outside: true, align: 'center', segments: 5});
                  $creationscount = $.ajax({ type: "GET", url: "/creations/creations_count", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $('#spinner').activity(false);

                  // template for matches grammars
                  if ($creationscount == '0') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#creatings').append(" = " + $creation_return_sequence).fadeTo(500, 1.0);
                    $("<span class='creationcounts'> &nbsp;&nbsp; ~ " + $letters_creation_count + " letters : " + $creationscount + " matches </span>").fadeTo(3000, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_creation_id');
                    $('#spinner').activity(false);
                  }

                  if ($creationscount == '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#creatings').append(" = " + $creation_return_sequence).fadeTo(500, 1.0);
                    $('label#sequence_sequence_creation_id').hide();
                    var $creationings = $("<span class='creationcounts'> &nbsp;&nbsp; ~ " + $letters_creation_count + " letters : " + $creationscount + " match\n <a href='/creations/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Creations Search</a></span>").fadeTo(900, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_creation_id');
                    $("<span class='creationcounts'> &nbsp;&nbsp; ~ " + $letters_creation_count + " letters : " + $creationscount + " match : <a href='/creations/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Creations Search</a></span>").fadeTo(900, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_creation_id');
                    $('#spinner').activity(false);
                  }

                  if ($creationscount > '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 8});
                    $('label#creatings').append(" = " + $creation_return_sequence).fadeTo(600, 1.0);
                    $("<span class='creationcounts'> &nbsp;&nbsp; ~ " + $letters_creation_count + " letters : " + $creationscount + " matches\n <a href='/creations/list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>please see Creations Search</a></span>").fadeTo(900, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_creation_id');
                    $('#spinner').activity(false);
                  }

                  $('#spinner').activity({outside: true, align: 'left', segments: 6});
                  $textscount = $.ajax({ type: "GET", url: "/sequences/texts_count", data: $sequencetext, async: true, dataType: 'script', success: function(data) { } }).responseText;
                  $('#spinner').activity(false);
        
                  if ($textscount == '0') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#textings').append(" = " + $text_return_value).fadeTo(600, 1.0);
                    $("<span class='textcounts'> &nbsp;&nbsp;&nbsp;&nbsp; ~ " + $letters_text_count + " letters : " + $textscount + " match</span>").fadeTo(700, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_text');
                    $('#spinner').activity(false);
                  }

                  if ($textscount == '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 7});
                    $('label#textings').append(" = " + $text_return_value).fadeTo(600, 1.0);
                    $("<span class='textcounts'> &nbsp;&nbsp;&nbsp;&nbsp; ~ " + $letters_text_count + " letters : " + $textscount + " match\n please see <a href='/sequences/text_list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>Texts Search</a></span>").fadeTo(700, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_text');
                    $('#spinner').activity(false);
                  }

                  if ($textscount > '1') {
                    $('#spinner').activity({outside: true, align: 'center', segments: 6});
                    $('label#textings').append(" = " + $text_return_value).fadeTo(600, 1.0);
                    $("<span class='textcounts'> &nbsp;&nbsp;&nbsp;&nbsp; ~ " + $letters_text_count + " letters : " + $textscount + " matches\n please see <a href='/sequences/text_list?sequencetext=" + $text_return_value + "' target='_blank' rel='noopener'>Texts Search</a></span>").fadeTo(700, 0.9).insertBefore('.sequence_edits form .form-group input#sequence_sequence_text');
                    $('#counttexts').append(" You have one or more matches, please see Texts Search").show();
                    $('#spinner').activity(false);
                  }

                  if ($sequencetext == "") {
                    $('#spinner').activity({outside: true, align: 'left', segments: 4});
                    $('.sequence_edit form input#sequencetext').val("Please Enter Your Data");
                    $('#spinner').activity(false);
                  }
 
                  if ($creationscount > $textscount) {
                    $('#spinner').activity({outside: true, align: 'center', segments: 9});
                    $('span.creationcounts').append(" : This is a new match on this Creation sequence");
                    $('#spinner').activity(false);
                  }

                  $('.sequence_edits form .form-group input#sequence_sequence_lense').focus();
                }
              }
            }
          }
        }
      }
    }
  );
});
