// SUBMIT on .new_entry_sequence form input#sequence_submit
$(document).ready(function() {
  $('.new_entry_sequence form input#sequencetext').focus();

  $('.new_entry_sequence form').on('submit', function(event) {
    var $sequencetext = $('.new_entry_sequence form input#sequencetext').val().trim();

    // Prevent form submission if the input is empty
    if (!$sequencetext) {
      event.preventDefault();
      alert('Please enter your text to process.');
      $('.new_entry_sequence form input#sequencetext').val('Enter Your Data, then tab out').fadeTo(400, 1.0).focus();
      return false;
    }

    // Process the input
    var $sequence_sequence_text = $('.sequence_new form .form-group input#sequence_sequence_text').val();
    var $text = $('.sequence_new form .form-group input#sequence_sequence_text').val();
    var $lense = $('.sequence_new form .form-group input#sequence_sequence_lense').val();
    var $textslense = ('sequencetext=' + $text + '0' + 'lensetext=' + $lense);
    var $submit = document.getElementById('sequence_sequence_text');
    var $lense_verification_value = $.ajax({ type: 'GET', url: '/sequences/lense_verified', data: $textslense, async: true, dataType: 'script' }).responseText;
    var $pattern = /letters/;
    var $texts = $lense_verification_value;
    var $result = $texts.match($pattern);
      $submit.form.submit = function() { return false; };
      if ($result == 'letters') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(500, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(500, 0.9);
        $('.new_entry_sequence form input#sequencetext').focus();
        $('.lensenotice').remove();
        $('<span class="lensenotice"> ' + $lense_verification_value + '</span>').fadeTo(1000, 0.9).insertAfter('input#sequence_sequence_lense');
        event.preventDefault();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext == '') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 0.9);
        $('.new_entry_sequence form input#sequencetext').val('Enter Your Data, then tab out');
        event.preventDefault();
        $submit.form.submit = function() { return false; };
        $('.new_entry_sequence form input#sequencetext').focus();
        }
      else if ($sequencetext == 'Enter Data, then tab out') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 0.9);
        $('.new_entry_sequence form input#sequencetext').val('Please enter data above');
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext == 'Enter Your Data, then tab out') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 1.0);
        $('.new_entry_sequence form input#sequencetext').val('Please enter data above');
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext == 'Please Enter Your Data, then tab out') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 1.0);
        $('.new_entry_sequence form input#sequencetext').val('Enter Data, then tab out');
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext == 'no letters remain after processing') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 1.0);
        $('.new_entry_sequence form input#sequencetext').val('enter a new entry');
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else if ($sequencetext == 'enter a new entry') {
        $('.new_entry_sequence form input#sequencetext').fadeTo(700, 0.2);
        $('.new_entry_sequence form input#sequencetext').fadeTo(400, 1.0);
        $('.new_entry_sequence form input#sequencetext').val('no letters remain after processing');
        event.preventDefault();
        event.stopPropagation();
        $submit.form.submit = function() { return false; };
        }
      else {
        if ($('.new_entry_sequence form input#sequencetext').val() != 'no letters remain after processing') {
          if ($('.new_entry_sequence form input#sequencetext').val() != 'enter a new entry') {
            if ($('.new_entry_sequence form input#sequencetext').val() != '') {
              if ($('.new_entry_sequence form input#sequencetext').val() != 'Enter Your Data, then tab out') {
                $submit.form.submit('');
              }
            }
          }
        }
      }
    })
  });
 