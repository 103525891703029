import "./src/jquery"
import "./src/ujs"
import "bootstrap"
import "chartkick/chart.js"

// Chartkick setup
import Chartkick from "chartkick"
import { Chart } from "chart.js"
// import Chart from "chart.js/auto"
Chartkick.addAdapter(Chart)

/* 202409051234 update from ahoy_captain-will branch at repo, hand copy paste of valid changes */
/* I believe I added this code first, and it did not work, so AI change to the above.
/* import "chartkick" */
/* import { Chartkick } from "chartkick" */
/* import Chart from "chart.js" */
/* Chartkick.addAdapter(Chart) */
/* import "Chart.bundle" */
/* import { Turbo } from "@hotwired/turbo-rails"
/* Turbo.start()
/* import "./src/jquery" */
/* import "./src/ujs" */

import "./sequencers/devise_sessions_new.js"
import "./sequencers/alert_when_no_value_in_search.js"
import "./sequencers/christ_mas_love_animation.js"
import "./sequencers/edit_sequence_focusin_focusout_sequencers.js"
import "./sequencers/edit_sequence_lense-verification.js"
import "./sequencers/edit_sequence_onsubmit.js"
import "./sequencers/edit_sequence_submit.js"
import "./sequencers/enter_your_data_reminder.js"
import "./sequencers/hide_anagram_new_link.js"
import "./sequencers/hide_sequence_created_new_link.js"
import "./sequencers/hide_sequence_new_sequence_ids.js"
import "./sequencers/index.js"
import "./sequencers/jquery.activity-indicator-1.0.0.js"
import "./sequencers/new_sequence_revised.js"
import "./sequencers/new_sequence_brighten-entries.js"
import "./sequencers/new_sequence_focusin_focusout_sequencers.js"
import "./sequencers/new_sequence_lense-verification.js"
import "./sequencers/new_sequence_onsubmit.js"
import "./sequencers/new_sequence_submit_disable.js"
import "./sequencers/new_sequence_submit_hide.js"
import "./sequencers/new_sequence_submit.js"
import "./sequencers/sequence_generators_link.js"
import "./sequencers/stripe-checkout.js"
import "./sequencers/visitor-email-submission.js"
import "./sequencers/visitor-focus.js"
import "./sequencers/visitor-how-to-completes-info-box.js"
import "./sequencers/visitor-how-to-creations-info-box.js"
import "./sequencers/visitor-how-to-lenses-info-box.js"
import "./sequencers/visitor-how-to-lexigrams-info-box.js"
import "./sequencers/visitor-how-to-perfect-anagrams-info-box.js"
import "./sequencers/visitor-how-to-singulars-info-box.js"
import "./sequencers/visitor-how-to-snips-info-box.js"
import "./sequencers/visitor-how-to-texts-info-box.js"
import "./sequencers/visitor-modal.js"
import "./sequencers/visitor-site-description-info-box.js"
import "./sequencers/visitor-webmaster-info-box.js"
import "./sequencers/signup-card.js"
import "./sequencers/sequencers_index.js"
import "./sequencers/sequencers_index_limited.js"