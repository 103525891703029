// FOCUSIN FOCUSOUT on .new_entry_sequence form input#sequencetext
// Query(function ($) { $(document).ready(  function() {

;(function () {

  "use strict";
  var NewSequence = function () {
    var $sequenceQueryForm = $('#new_sequence_search');
    var $responseFormContainer = $('#response-form-container');
    var $responseForm = $('#response-form');
    var $queryInput = $('#query-input');
    var $charCount = $('#char-count');
    var lenseVerifyDebounceTimeout;

    
    var sequenceId = $sequenceQueryForm.data('sequence-id') || null;

    var $queryInput = $sequenceQueryForm.find('input[name="query"]')
    var queryString;

    var initQueryFormSubmission = function() {
      $sequenceQueryForm.on('submit',function (event) {
        event.preventDefault();
        event.stopPropagation();
        fetchAndRenderSequenceForm();
      });
    }

    var initCharsCounter = function() {
      var initial = 255 - $queryInput.val().length;
      $charCount.text(initial);
      $queryInput.on('input', function() {
        var remaining = 255 - $(this).val().length;
        if (remaining == 0) {
          $charCount.css('color', 'red');
        } else {
          $charCount.css('color', 'black');
        }
        $charCount.text(remaining);
      });
    }


    var initQueryFocusInFocusOut = function() {
      $queryInput.on('blur',function (event) {
        fetchAndRenderSequenceForm();
      });
    }

    var initLenseValidationCheck = function() {

      var $sequenceLenseInput = $responseForm.find("#sequence_sequence_lense");
      var $sequenceTextEntry = $responseForm.find("#sequence_sequence_text");
      $sequenceLenseInput.on('input', function(event){
        clearTimeout(lenseVerifyDebounceTimeout);

        var lenseValue = event.target.value.trim();
        var queryString = $sequenceTextEntry.val()+"0lensetext=" +lenseValue
        if (queryString.length > 0) {
          $('#verify-lense-spinner').show();
          lenseVerifyDebounceTimeout = setTimeout(function() {
            $.ajax({
              type: 'get',
              url: "/v2/sequences/verify_lense?query=" +queryString,
              success: function (response) {
                var $noticeElement = $(event.target).parent().siblings(".notice");
                var noticeClass;
                
                if (response.verification == true) {
                  noticeClass = 'notice green';
                  $responseForm.data("valid", true);
                }
                else {
                  noticeClass = 'notice red';
    
                  $responseForm.data("valid", false);
                } 
    
                $noticeElement.text(response.message);
                $noticeElement.removeClass().addClass(noticeClass);
              },
              complete: function() {
                $('#verify-lense-spinner').hide();
              }
            });
          }, 500);
        }else {
          $('#verify-lense-spinner').hide();
      }
      });
    };

    var renderSequenceForm = function(){
      var $toAnimate = $responseForm.find('.animate')
      $toAnimate.hide();
      $responseFormContainer.html($responseForm);
      
      var delay = 200; // Delay between each section animation

      $toAnimate.each(function(index) {
        var section = $(this);
        setTimeout(function() {
            section.fadeIn('slow');
        }, delay * index);
    });
    }

    var handleSequenceFormSubmission = function(){
      $responseForm.on("submit", function(event){
        var isFormValid = $responseForm.data("valid");
        if(!isFormValid){
          alert("Please make sure fields are valid");
          event.stopPropagation();
          event.preventDefault();
        } 
      })
    }

    var fetchAndRenderSequenceForm = function() {
      queryString = $queryInput.val().trim();
      if (queryString !== '') {
        $('#search-spinner').show();
        $.ajax({
          type: 'get',
          url: "/v2/sequences/response_form?query=" + queryString+"&sequenceId=" + sequenceId,
          success: function (responseHtml) {
            var $responseHtml = $(responseHtml);
            $responseForm = $responseHtml.find("#response-form");
            renderSequenceForm();
            initLenseValidationCheck();
            handleSequenceFormSubmission();
          },
          complete: function() {
            // Hide the spinner after the AJAX call is complete
            $('#search-spinner').hide();
          }
        });
      } else {
        $('#search-spinner').hide();
      }
    }

    var initEventListeners = function () {
      initCharsCounter();
      initQueryFormSubmission();
      initLenseValidationCheck();
     };

    this.configureForm = function () {
      initEventListeners();
      // initQueryFocusInFocusOut();
    };
  };

  $(document).ready(function () {
    if ($('body').data('controller') === 'sequences' && (($('body').data('action') === 'new')||($('body').data('action') === 'edit'))) {
      var newSequence = new NewSequence();
      newSequence.configureForm();
    }
  });
})();