// SUBMIT on .new_entry_sequence form input#sequence_submit
$(document).ready( function() {
  $('.sequence_edit form').bind(
    'submit',function(event) {
      var $submit = document.getElementById('sequencetext');
      event.preventDefault();
      $submit.form.submit = function() { return false; };
      $('.sequence_edits form input#sequence_text').focus();
  })
});
