document.addEventListener('DOMContentLoaded', function() {
  // Query the modal by its class or id
  const modal = document.querySelector('#visitor-modal');

  // Query the button that opens the modal
  const openBtn = document.querySelector('#visitors-stararts');

  // Query the button that closes the modal
  const closeBtn = document.querySelector('.modal-header button');

  // Show the modal
  if(openBtn){
    openBtn.addEventListener('click', function() {
      modal.style.display = 'block';
    });
  };

  if(closeBtn){
    // Hide the modal
    closeBtn.addEventListener('click', function() {
      modal.style.display = 'none';
    });
  };

  // Hide the modal if clicked outside of the modal content
  window.addEventListener('click', function(event) {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  });
});
