$(document).ready(  function() {
  $('span.textcounts').hide();
  $('span.creationcounts').hide();
  $('span.completecounts').hide();
  $('span.lexigramcounts').hide();
  $('span.singularcounts').hide();
  // $('.sequence_new form .form-group label#sequencelense').hide();
  // $('.sequence_new form .form-group input#sequence_sequence_lense').hide();
  $('.sequence_new form .form-group label#sequencelense').fadeTo(500, 1.0);
  $('.sequence_new form .form-group input#sequence_sequence_lense').fadeTo(500, 1.0);


  $('.sequence_new form .form-group label#sequencedescription').hide();
  $('.sequence_new form .form-group input#sequence_description').hide();
  $('.sequence_new form .form-group label#sequencereference').hide();
  $('.sequence_new form .form-group input#sequence_reference').hide();
  $('.sequence_new form .form-group label#sequenceurl').hide();
  $('.sequence_new form .form-group input#sequence_url').hide();
  $('.sequence_new form input#docNewCancelButton').hide();
  $('.new_entry_sequence form input#sequencetext').bind('focusin',function(event) {
    $('.lensenotice').remove(); // see new_sequence_lense_verification.js
    $('.sequence_new form label.showing').fadeTo(500, 1.0);  // affects only sequence_text label
    $('span.textcounts').empty();
    $('span.creationcounts').empty();
    $('span.completecounts').empty();
    $('span.lexigramcounts').empty();
    $('span.singularcounts').empty();
    // $('input#sequencetext').fadeTo(300, 0.7).delay(100).end();
    // $('input#sequencetext').fadeTo(300, 1.0);
    $('.sequence_new form label#textings').fadeTo(100, 0.6);
    $('.sequence_new form label#creatings').fadeTo(100, 0.6);
    $('.sequence_new form label#completings').fadeTo(100, 0.6);
    $('.sequence_new form label#lexigramings').fadeTo(100, 0.6);
    $('.sequence_new form label#singularings').fadeTo(100, 0.6);
    $('input#sequencetext'); })
  .bind('focusout',function(event) {
    $('.sequence_new form label.showing').fadeTo(100, 1.0);
//  $('.form-group label.hiding').hide();             // affects only four sequence_id labels
    $('.form-group label.hiding').fadeTo(100, 1.0);   // affects only four sequence_id labels
    $('.sequence_new form .form-group label#sequencelense').fadeTo(500, 1.0);
  })
});

$(document).ready( function() {
  $('.sequence_new form .form-group input#sequence_sequence_lense').bind('focusin',function(event) {
    $('.lensenotice').remove(); // see new_sequence_lense_verification.js
    $('.sequence_new form label#sequencelense').fadeTo(500, 1.0);
    $('.sequence_new form label#sequencedescription').fadeTo(500, 0.5);
    $('.sequence_new form label#sequencereference').fadeTo(500, 0.5);
    $('.sequence_new form label#sequenceurl').fadeTo(500, 0.5);
    }).bind('focusout',function(event) {
      $('.sequence_new form .form-group label#sequencedescription').fadeTo(300, 0.5);
      $('.sequence_new form .form-group label#sequencereference').fadeTo(300, 0.5);
      $('.sequence_new form .form-group label#sequenceurl').fadeTo(300, 0.5);
      $('.sequence_new form label#sequenceurl').fadeTo(300, 0.5);
      $('.sequence_new form label#singularings').fadeTo(300, 0.5);
      $('.sequence_new form label#lexigramings').fadeTo(300, 0.5);
      $('.sequence_new form label#completings').fadeTo(300, 0.5);
      $('.sequence_new form label#creatings').fadeTo(300, 0.5);
      $('.sequence_new form label#textings').fadeTo(300, 0.5);
      $('.sequence_new form label.showing').fadeTo(300, 0.5);
  })
});
                                             
$(document).ready( function() {
  $('.sequence_new form .form-group input#sequence_description').bind('focusin',function(event) {
    $('.sequence_new form .form-group label#sequencelense').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequencedescription').fadeTo(100, 1.0);
    $('.sequence_new form .form-group label#sequencereference').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequenceurl').fadeTo(300, 0.5);
  });
});

$(document).ready( function() {
  $('.sequence_new form .form-group input#sequence_reference').bind('focusin',function(event) {
    $('.sequence_new form .form-group label#sequencelense').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequencedescription').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequencereference').fadeTo(300, 1.0);
    $('.sequence_new form .form-group label#sequenceurl').fadeTo(300, 0.5);
  });
});

$(document).ready( function() {
  $('.sequence_new form .form-group input#sequence_url').bind('focusin',function(event) {
    $('.sequence_new form .form-group label#sequencelense').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequencedescription').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequencereference').fadeTo(300, 0.5);
    $('.sequence_new form .form-group label#sequenceurl').fadeTo(300, 1.0);
  });
});