document.addEventListener('DOMContentLoaded', function() {
  const serverErrorMessage = document.body.getAttribute('data-server-error');
  const networkErrorMessage = document.body.getAttribute('data-network-error');
  
  const buttons = document.querySelectorAll('[class="checkout-button"]');
  buttons.forEach(button => {
    button.addEventListener('click', async function(e) {
      e.preventDefault();
      const priceId = this.getAttribute('data-price-id');
      try {
        const response = await fetch(`/stripe/generate_checkout_url/${priceId}`);
        if (!response.ok) {
          const errorData = await response.json();
          alert(serverErrorMessage.replace('%{error}', errorData.error)); // Use localized message
          return;
        }
        const data = await response.json();
        window.location.href = data.url;
      } catch (error) {
        alert(networkErrorMessage); // Use localized message
      }
    });
  });
});
