// RESET affects sequence/new.html.erb
$(document).ready(  function() {
  $('#docNewCancelButton').click( function() {
    $('input#sequencetext').fadeTo(300, 0.3).delay(100).end();
    $('input#sequencetext').fadeTo(300, 1.0).end();
    $('.sequence_new .counts').hide();
    $('input#sequencetext').show();
    $('input#sequencetext').val("");
    $('.new_entry_sequence form input#sequencetext').focus();
  })
});
