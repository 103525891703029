// verifies the sequence_text entry is a subset of the sequence_lense entry
$(document).ready( function() {
  $('.sequence_edits form .form-group input#sequence_sequence_lense').bind('focusout',function(event) {
    $('#spinner').activity({outside: true, align: 'left', segments: 2});
    var $text = $('input#sequence_sequence_text').val();
    var $lense = $('input#sequence_sequence_lense').val();
    $textslense = ("sequencetext=" + $text + "0" + "lensetext=" + $lense);
    var $lense_verification_value = $.ajax({ type: "GET", url: "/sequences/lense_verified", data: $textslense,  async: true, dataType: 'script', success: function(data) {  } }).responseText;
    $('<p class="lensenotice">' + $lense_verification_value + '</p>').fadeTo(1000, 0.9).insertAfter('label#sequencelense');
    $('#spinner').activity(false);
  })
});
    